<template>
    <section class="minimal-information-edit">
        <InfosCampanhaFormEdit />
        <DoseQuantificationCampaignEditForm />
    </section>
</template>

<script>
import InfosCampanhaFormEdit from '../Forms/InfosCampaignEditForm.vue';
import DoseQuantificationCampaignEditForm from '../Forms/DoseQuantificationCampaignEditForm.vue';

export default {
    components: { InfosCampanhaFormEdit, DoseQuantificationCampaignEditForm },

    data() {
        return {
            savingInfosCampanha: false,
        }
    }
}
</script>

<style>

</style>
<template>
    <section class="formCampanha">

        <TimedAlert 
            variant="warning" 
            message="Para remover uma vacina é necessário remover todas as distribuições de doses nas unidades e regiões e remover os preços manualmente" 
            :show="showAlertRemoveVaccine"
            @closedAlert="showAlertRemoveVaccine = false" 
        />

        <validation-observer ref="campanhaEditRules">
            <b-form>
                <b-row>
                    <b-col lg="6" md="6" sm="12" class="pr-lg-0">
                        <b-form-group label="Nome da Campanha*" label-for="name-campanha">
                            <validation-provider
                                #default="{ errors }"
                                name="nameCampanha"
                                rules="required"
                            >
                                <b-form-input 
                                    :state="errors.length > 0 || form.errors.nameCampanha ? false:null"
                                    v-mask="('X'.repeat(200))"
                                    id="name-campanha" 
                                    v-model="form.nameCampanha"
                                    autocomplete="off"
                                    placeholder="Dê o nome da campanha"
                                />
                                <small v-if="errors[0]" class="text-danger d-block">
                                    Este campo é de preenchimento obrigatório.
                                </small>
                                <small v-if="form.errors.nameCampanha" class="text-danger">
                                    {{ form.errors.nameCampanha }}
                                </small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col lg="3" md="6" sm="12" class="pr-lg-0">
                        <b-form-group label="Doença*" label-for="disease" >
                            <v-select 
                                id="disease"
                                v-model="selectedDisease"
                                :loading="loadingDisease"
                                :reduce="(option => option.id_doenca)"
                                variant="primary"
                                disabled
                                :options="disease"
                                label="nome_tecnico"
                                placeholder="Selecione uma doença"
                            >
                            </v-select>
                        </b-form-group>
                    </b-col>
                    <b-col lg="5" md="6" sm="12">
                        <validation-provider
                            #default="{ errors }"
                            name="Vacina"
                            rules="required"
                        >
                            <b-form-group 
                                label="Vacina*" 
                                label-for="vaccines" 
                                :state="errors.length > 0 || form.errors.vaccines ? false:null">
                                <v-select 
                                    id="vaccines"
                                    v-model="form.vaccines"
                                    :loading="loadingVacinas"
                                    :options="vaccines"
                                    :closeOnSelect="false"
                                    multiselect
                                    multiple
                                    @option:selected="selectedVaccines"
                                    @option:deselected="diselectVaccineCheck"
                                    :selectable="() => form.vaccines.length < 3"
                                    label="nome_tecnico"
                                    placeholder="Selecione uma ou mais"
                                >
                                    <span slot="no-options">Nenhuma opção selecionável.</span>
                                </v-select>
                                <small v-if="errors[0]" class="text-danger d-block">
                                    Este campo é de preenchimento obrigatório.
                                </small>
                                <small v-if="form.errors.vaccines" class="text-danger">
                                    {{ form.errors.vaccines }}
                                </small>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12" md="12" sm="12" class="mt-2">
                        <validation-provider
                            #default="{ errors }"
                            name="adhesion"
                            rules="required"
                        >
                        <input type="hidden" v-model="adhesion"/>
                        <adhesion-no-workers-input
                        :adhesion="adhesion"
                        :disabled="status != 'Rascunho'"
                        @setPartialAdhesion="setPartialAdhesion"
                        />
                        <small v-if="errors[0]" class="text-danger d-block">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        </validation-provider>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';

import vSelect from 'vue-select';
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BAlert, BFormRadioGroup } from 'bootstrap-vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'

import { busEvent } from '@/main';
import AdhesionNoWorkersInput from '@/views/components/custom/Inputs/AdhesionNoWorkersInput.vue';

export default {

    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BAlert,BFormRadioGroup,
        ValidationProvider, ValidationObserver, vSelect, TimedAlert,
        AdhesionNoWorkersInput
    },

    data() {
        return {
            required,
            loadingVacinas: false,
            loadingDisease: true,
            selectedDisease: null,
            adhesion: null,
            option: [{
                value: true,
                text: "Sim"
            },
            {
                value: false,
                text: "Não"
            },
            ],
            form: {
                nameCampanha: null,
                vaccines: [],
                errors: {
                    nameCampanha: null, 
                    vaccines: null
                }
            },
            status: null,
            disease: [],
            vaccines: [],
            vaccinesPreviousSelected: [],
            vaccineDeselected: null,
            showAlertRemoveVaccine: false
        }
    },

    mounted() {
        busEvent.$on('saveCampanha', () => {
            this.validateForm();
        });

        busEvent.$on('dataCampanha', (dataCampanha) => {
            this.fillFields(dataCampanha.campanha);
        });

        busEvent.$on('vaccineHasPrice', (hasPrice) => {
            this.restoreVaccineSelected(hasPrice, 'price');

            if(!hasPrice) { 
                busEvent.$emit('checkDistributionRegionLink', this.vaccineDeselected.id_vacina);
            } 
        });

        busEvent.$on('distributionRegionHasDoses', (hasPrice) => {
            this.restoreVaccineSelected(hasPrice, 'region');

            if(!hasPrice) { 
                busEvent.$emit('checkDistributionUnidadeLink', this.vaccineDeselected.id_vacina);
            }
        });
        
        busEvent.$on('distributionUnidadeHasDoses', (hasPrice) => {
            this.restoreVaccineSelected(hasPrice, 'unidade');
        });

        busEvent.$on('vaccineExclusion', (index) => {
            if(Object.values(this.form.vaccines)[index]){
                this.diselectVaccineCheck(Object.values(this.form.vaccines)[index]);
            }
        });
    },

    methods: {
        validateForm() {
            this.$refs.campanhaEditRules.validate().then(success => {
                if (success) {
                    const ids_vacina = this.form.vaccines.map((vacina) => vacina.id_vacina);

                    const minimalDataCampaign = {
                        descricao: this.form.nameCampanha,
                        permite_adesao_parcial: this.adhesion,
                        id_vacina: ids_vacina
                    }

                    busEvent.$emit('minimalDataCampaign', minimalDataCampaign);
                }
            })
        },

        fillFields(dataCampanha) {
            this.form.nameCampanha = dataCampanha.descricao;
            this.status = dataCampanha.situacao;
            this.adhesion = dataCampanha.permite_adesao_parcial;

            this.loadDisease().then(() => {
                this.selectedDisease = dataCampanha.id_doenca;
            });

            this.loadVaccines(dataCampanha.id_doenca).then(() => {                
                const vaccinesSelected = dataCampanha.campanha_vacina.map(vaccine => vaccine['id_vacina']);

                const vaccines = this.vaccines.map(vaccine => {
                    if(vaccinesSelected.includes(vaccine.id_vacina)) {
                        return vaccine;
                    }
                });

                this.vaccinesPreviousSelected = vaccines.filter(vaccine => vaccine !== undefined);

                this.form.vaccines = this.vaccinesPreviousSelected;
                this.emitEventSelectedVaccines();
            });
        },

        selectedVaccines() {
            busEvent.$emit('disableButtonSaveNewVaccine', true);

            this.vaccinesPreviousSelected = this.form.vaccines;
            
            this.emitEventSelectedVaccines();
        },

        diselectVaccineCheck(vaccineDeselected) {
            busEvent.$emit('disableButtonSaveNewVaccine', false);
            
            this.vaccineDeselected = vaccineDeselected;

            busEvent.$emit('checkPrecificationLink', vaccineDeselected.id_vacina);
        },  

        restoreVaccineSelected(hasPrice, type) {
            if(hasPrice || (!hasPrice && type != 'unidade')) {
                this.form.vaccines = this.vaccinesPreviousSelected;

                this.showAlertRemoveVaccine = true;
            } else if(!hasPrice && type == 'unidade') {
                this.form.vaccines = this.form.vaccines.filter(vaccine => vaccine.id_vacina != this.vaccineDeselected.id_vacina);

                this.emitEventSelectedVaccines();
                this.vaccineDeselected = [];
                this.vaccinesPreviousSelected = this.form.vaccines;

                this.showAlertRemoveVaccine = false;

                busEvent.$emit('disableButtonSave', { error: false });
                busEvent.$emit('cleanErrors');
            }
            if(this.form.vaccines.length == 0){
                busEvent.$emit('disableButtonSaveNewVaccine', true);
            }
        },

        async loadDisease() {
            const { data } = await this.$http.get(this.$api.saveAndGetDoenca(), {params: { ativo: true }});

            this.disease = data.sort((a, b) => a.nome_tecnico.localeCompare(b.nome_tecnico));
            this.loadingDisease = false;
        },

        async loadVaccines(idDisease) {
            this.loadingVacinas = true;

            const { data } = await this.$http.get(this.$api.getVaccinesOfDisease(idDisease));
            
            this.vaccines = data;  
            this.loadingVacinas = false;
        },

        emitEventSelectedVaccines() {
            busEvent.$emit('vaccinesEdited', this.form.vaccines);
        },
        setPartialAdhesion(val) {
            this.adhesion = val;
        }
    }
}
</script>

<style>

</style>
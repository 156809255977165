<template>
    <b-row>
        <b-col xl="7" lg="7" md="12" sm="12"
            class="pb-md-2">

            <ErrorNumberOfDoses 
                :modalShow="modalErrorDoses.showModal"
                :typeModal="modalErrorDoses.type"
                @confirmed="closeModal"
            />

            <b-form>
                <app-collapse accordion>
                    <app-collapse-item
                        v-for="(region, index) in regions"
                        :key="index"
                        class="mb-1 item-unit"
                        title="unidade"
                        @visible="loadUnidades(region)"
                    >
                        <template slot="header">
                            <h4 class="text-custom-blue mb-0">{{ region.descricao }}</h4>
                        </template>

                        <div v-if="!region.unidades" class="text-center">
                            <div class="spinner-border text-custom-blue" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>

                        <b-table
                            v-else
                            ref="tableDosesUnit"
                            id="table-doses-unit"
                            responsive
                            :stickyColumn="true"
                            :fields="region.fields"
                            :items="region.unidades"
                            no-local-sorting
                            :no-sort-reset="true"
                            tbody-tr-class="custom-center-content"
                            table-class="custom-table"
                        >
                            <template #cell(actions)="row">
                                <feather-icon v-if="row.item.tooltip"
                                    v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
                                    title="Espaço para reserva de doses a serem distribuídas futuramente."
                                    icon="InfoIcon" 
                                    size="18"
                                    color="#2772C0"
                                />
                            </template>
                            <template #cell(descricao)="row">
                                <span class="custom-font-bold">{{ row.item.descricao }}</span>
                            </template>
                            <template #cell(noPromotion)="row">
                                <b-form-input 
                                    v-model="row.item.noPromotion"
                                    @keypress="$helpers.doNotAllowZeroValue($event, row.item, 'noPromotion')"
                                    v-mask="'##########'"
                                    autocomplete="off"
                                    class="custom-input-number-doses"
                                    id="no-promotion"
                                    @update="() => {
                                        CalcTable.sumAllRowTable(row.item);
                                        sumColumnDistribution(region);
                                        validateHasDosesAvailable();
                                        DistributionUnitService.checkIfOnlyHasReservation(busEvent, regions, selectedVaccine);
                                        fillSumary(region);
                                    }"
                                    placeholder="---"
                                />
                            </template>
                            <template #cell(withPromotion)="row">
                                <b-form-input 
                                    v-model="row.item.withPromotion"
                                    @keypress="$helpers.doNotAllowZeroValue($event, row.item, 'withPromotion')"
                                    v-mask="'##########'"
                                    autocomplete="off"
                                    class="custom-input-number-doses"
                                    id="with-promotion"
                                    @update="() => {
                                        CalcTable.sumAllRowTable(row.item);
                                        sumColumnDistribution(region);
                                        validateHasDosesAvailable();
                                        DistributionUnitService.checkIfOnlyHasReservation(busEvent, regions, selectedVaccine);
                                        fillSumary(region);
                                    }"
                                    placeholder="---"
                                />
                            </template>
                            <template #cell(totalDoses)="row">
                                <span class="custom-font-bold m-0">{{ row.item.totalDoses ? row.item.totalDoses : '---' }}</span>
                            </template>
                        </b-table>
                    </app-collapse-item>
                </app-collapse>
            </b-form>
        </b-col>
        <b-col lg="4" md="4" sm="2" class="separatorBar ml-2 pl-2 pb-5 pb-md-0 mb-md-4" id="doseSectionUnit">
            <DoseSummary 
                :dosesNumber="doses"
                :hasIncentive="hasIncentivePromotion"
            />
        </b-col>
    </b-row>
</template>

<script>
import { BTable, BForm, BRow, BCol, BFormInput } from 'bootstrap-vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import DoseSummary from '@/views/components/custom/DosesSummary/DosesSummaryregionUnit.vue';
import ErrorNumberOfDoses from '@/views/components/custom/modals/ModalErrorNumberOfDoses.vue';
import CalcTable from '../../Services/CalculatetableDoses';
import FillUnidades from '../../Services/FillRegionsUnidade';
import TableFieldControl from '../../Services/TableFieldControl';
import DistributionUnitService from '../../Services/DistributionUnidadeFormService';
import { getInfoUserDepartamento, getRegionsConsulted } from "@core/utils/store/getStore";
import { setRegionConsulted } from '@core/utils/store/setStore';
import { busEvent } from '@/main';
import followScreen from '@/views/components/custom/DosesSummary/followScreen';

export default {
    components: {
        BTable, BForm, BRow, BCol, BFormInput,
        AppCollapse, AppCollapseItem, DoseSummary,
        ErrorNumberOfDoses
    },

    props: {
        hasPromotion: {
            required: true,
            type: Boolean,
        },
        vaccines: {
            required: true
        },
        editedVaccines: {
            required: true
        },
        idVaccine: {
            required: true
        },
        incentives: {
            required: true,
            type: Object|null
        },
        numberOfDoses: {
            required: true,
            type: Object|null
        },
        regionsSelected: {
            required: true,
            type: Object|null
        },
        situationCampaign: {
            required: true,
            type: String
        }
    },

    data() {
        return {
            DistributionUnitService,
            busEvent,
            regions: null,
            CalcTable,
            doses: {
                totalDosesWithPromotion: 0,
                totalDosesWithoutPromotion: 0,

                reservationWithPromotion: 0,
                reservationWithoutPromotion: 0,

                totalDistributedDosesWithPromotion: 0,
                totalDistributedDosesWithoutPromotion: 0,

                controlDosesWithPromotion: 0,
                controlDosesWithoutPromotion: 0,
            },
            modalErrorDoses: {
                showModal: false,
                type: 'rascunho-unidade'
            },
            hasIncentivePromotion: this.$props.hasPromotion,
            infosVaccineCampaign: this.$props.vaccines,
            selectedVaccine: this.$props.idVaccine,
            selectedIncentives: this.$props.incentives,
            situationCampaignSelected: this.$props.situationCampaign,
            selectedVaccinesEdited: this.$props.editedVaccines,
            devideByRegion: !getInfoUserDepartamento('divisao_por_regiao'),
            idsRegionConsulted: [],
        }
    },

    mounted() {
        window.addEventListener('scroll', followScreen.verifyScroll(false));
        busEvent.$on('saveCampanha', this.saveDataDistributionUnidade);
        busEvent.$on('checkDistributionUnidadeLink', this.checkIfVaccineHasDistributionUnidade);
        busEvent.$on('checkErrorDistributionUnit', this.checkErrorDistribution);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', followScreen.verifyScroll(false))
        busEvent.$off('saveCampanha', this.saveDataDistributionUnidade);
        busEvent.$off('checkDistributionUnidadeLink', this.checkIfVaccineHasDistributionUnidade);
        busEvent.$off('checkErrorDistributionUnit', this.checkErrorDistribution);
    },

    methods: {
        checkErrorDistribution(vaccineId) {
            if(vaccineId == this.selectedVaccine) {
                setTimeout(() => { this.validateHasDosesAvailable(); }, 500); 
            }
        },

        closeModal() {
            this.modalErrorDoses.showModal = false;
        },

        async loadUnidades(region, fillSumary = true) {
            FillUnidades.updateSummary(this, region);
            this.controlColumns(region);
            this.sumColumnDistribution(region);
            if(fillSumary) { this.fillSumary(region) }

            this.regions.map(r => {
                r.open = false;
            });

            region.open = true;

            if(!region.hasOwnProperty('unidades')) {
                let regionConsulted = getRegionsConsulted(region.idRegiao);

                if (regionConsulted == null) {
                    await this.$http.get(this.$api.unidadeRegiao(region.idRegiao))
                    .then(({ data }) => {
                        setRegionConsulted(region.idRegiao, data);
                        regionConsulted = getRegionsConsulted(region.idRegiao);
                    });
                }

                this.regions.map((regionUnidade) => {
                    if (regionUnidade.idRegiao == region.idRegiao) {
                        FillUnidades.fillUnidadesByRegion(this,regionUnidade,regionConsulted);
                        this.controlColumns(regionUnidade);
                        this.fillDataByRegion(regionUnidade.idRegiao);
                        this.sumColumnDistribution(regionUnidade);
                    }
                });
            }
        },

        fillDataByRegion(idRegion) {
            const vacinaEstimativa  = this.infosVaccineCampaign.filter(vaccine => vaccine.id_vacina == this.selectedVaccine)[0];

            if(!vacinaEstimativa) { return; }

            const region = this.regions.filter(regionSelected => regionSelected.idRegiao == idRegion)[0];

            if(this.hasIncentivePromotion) {
                const estimativaPromotion = vacinaEstimativa.estimativa.filter(estimativaIncentivo => estimativaIncentivo.incentivo.descricao != 'Normal')[0];
                if(estimativaPromotion) { 
                    const dataRegiaoPromotion = estimativaPromotion.campanha_regiao.filter(campanhaRegiao => campanhaRegiao.id_regiao == idRegion)[0];

                    this.fillValuesUnidade(region, dataRegiaoPromotion, estimativaPromotion, 'withPromotion');
                }
            }

            const estimativaNoPromotion = vacinaEstimativa.estimativa.filter(estimativaIncentivo => estimativaIncentivo.incentivo.descricao == 'Normal')[0];
            if(!estimativaNoPromotion) { return }

            const dataRegiaoNoPromotion = estimativaNoPromotion.campanha_regiao.filter(campanhaRegiao => campanhaRegiao.id_regiao == idRegion)[0];

            this.fillValuesUnidade(region, dataRegiaoNoPromotion, estimativaNoPromotion, 'noPromotion');
        },

        async fillValuesUnidade(region, dataRegiao, estimativa, promotion) {
            if(!region.unidades || !dataRegiao) { return; }

            await region.unidades.map(unidade => {
                if(unidade.idUnidade) {
                    const dataUnidade = dataRegiao.campanha_vacina_unidade
                        .filter(dataUnit => dataUnit.id_unidade == unidade.idUnidade)[0];
    
                    unidade[promotion] = dataUnidade ? dataUnidade.quantidade : null;
                } else if (this.devideByRegion){
                    unidade[promotion] = estimativa.reserva;
                } else {
                    unidade[promotion] = dataRegiao.reserva ? dataRegiao.reserva : null;
                }
                CalcTable.sumAllRowTable(unidade);
            });

            this.validateHasDosesAvailable(false);
            DistributionUnitService.checkIfOnlyHasReservation(busEvent, this.regions, this.selectedVaccine);
        },

        controlColumns(region) {
            if(region.hasOwnProperty('fields')) {
                TableFieldControl.removePromotionNoIncentive(this.hasIncentivePromotion, region.fields);
                TableFieldControl.removePromotionDoses(region.withPromotion, region.fields);
                TableFieldControl.removeWithoutPromotionDoses(region.noPromotion, region.fields);
                if(this.$refs.tableDosesUnit) {
                    this.$nextTick(() => {
                        this.$refs.tableDosesUnit.map((table) => {
                            table.refresh();
                        })
                    })   
                }
            }
        },

        fillSumary(region) {
            if(region.hasOwnProperty('dosesSummary')) {
                this.doses.totalDosesWithPromotion = region.dosesSummary.totalDosesWithPromotion;
                this.doses.totalDosesWithoutPromotion = region.dosesSummary.totalDosesWithoutPromotion;
                this.doses.controlDosesWithPromotion = region.dosesSummary.controlDosesWithPromotion;
                this.doses.controlDosesWithoutPromotion = region.dosesSummary.controlDosesWithoutPromotion;

                this.doses.totalDistributedDosesWithPromotion = region.dosesSummary.totalDistributedDosesWithPromotion;
                this.doses.totalDistributedDosesWithoutPromotion = region.dosesSummary.totalDistributedDosesWithoutPromotion;

                this.doses.reservationWithPromotion = region.dosesSummary.reservationWithPromotion;
                this.doses.reservationWithoutPromotion = region.dosesSummary.reservationWithoutPromotion;
            }
        },

        sumColumnDistribution(region) {
            if(region.hasOwnProperty('unidades')) {
                CalcTable.sumAllColumTable(region.dosesSummary, region.unidades);
            }
        },

        saveDataDistributionUnidade() {
            let dataDistribution = [];

            this.regions.map(region => {
                const dataReservation = DistributionUnitService.prepareDataToSaveReservation(
                    region.unidades, this.selectedIncentives
                );
                const dataDistributionDose = DistributionUnitService.prepareDataSaveDoseDistribution(
                    region, this.selectedIncentives
                );

                dataDistribution.push({
                    idRegion: region.idRegiao,
                    reserva: dataReservation ? dataReservation : [],
                    idVaccine: this.selectedVaccine,
                    distribution: dataDistributionDose ? dataDistributionDose : []
                });
            });

            busEvent.$emit('doseDistributionUnidadePrepare', dataDistribution);
        },

        validateHasDosesAvailable(emitError = true) {
            let allDosesAvailable = 0;
            let error = false;

            const hasNegativeDose = this.regions.some(region => {
                if(
                    region.dosesSummary.controlDosesWithPromotion < 0 || 
                    region.dosesSummary.controlDosesWithoutPromotion < 0
                ) { return true; }
                
                allDosesAvailable += region.dosesSummary.controlDosesWithPromotion + region.dosesSummary.controlDosesWithoutPromotion;
            });

            if(hasNegativeDose) {
                this.emitErrorDoses(true, emitError);
                return;
            }

            if(allDosesAvailable == 0) { error = true; }

            busEvent.$emit('canSaveScheduleDistributionUnidade', [error, this.selectedVaccine]);

            if(this.situationCampaignSelected != 'Rascunho') {
                this.emitErrorDoses(!error, emitError);
            } else {
                let errorRascunho = false;

                if(allDosesAvailable < 0) { errorRascunho = true }  

                this.emitErrorDoses(errorRascunho, emitError);
            }

            allDosesAvailable = 0;
        },

        emitErrorDoses(error, emit) {
            if(emit) (
                busEvent.$emit('errorDoseDistribution', { 
                    error,
                    vaccineId: this.selectedVaccine,
                    type: 'unidade'
                })
            )
        },

        checkIfVaccineHasDistributionUnidade(vaccineId) {
            if(this.selectedVaccine == vaccineId) {
                if(this.regions.length == 0) {
                    busEvent.$emit('distributionUnidadeHasDoses', false);
                    return;
                }

                let distributions = [];
                const reservations = [];

                this.regions.map(region => {
                    distributions.push(DistributionUnitService.prepareDataSaveDoseDistribution(
                        region, this.selectedIncentives
                    ));

                    reservations.push(DistributionUnitService.prepareDataToSaveReservation(
                        region.unidades, this.selectedIncentives
                    ));
                })

                const hasDistribution = distributions.reduce(distribution => distribution.length > 0);

                const hasReservation = reservations.reduce(reservation => reservation);

                if(hasDistribution.length > 0 || hasReservation) {
                    busEvent.$emit('distributionUnidadeHasDoses', true);
                    return;
                }

                busEvent.$emit('distributionUnidadeHasDoses', false);
            }
        }
    },

    watch: {
        hasPromotion(value) {
            this.hasIncentivePromotion = value; 

            if(this.regions) {
                this.regions.map(region => {
                    TableFieldControl.removePromotionNoIncentive(value, region.fields);
                });
            }
        },

        vaccines(vaccines) {
            this.infosVaccineCampaign = vaccines;
        },

        idVaccine(idVaccine) {
            this.selectedVaccine = idVaccine;
        },

        incentives(incentives) {
            this.selectedIncentives = incentives;
        },

        regionsSelected(regions) {
            this.regions = regions;

            this.regions.map((region) => {
                if (this.idsRegionConsulted.indexOf(region.idRegiao) == -1) {
                    this.loadUnidades(region, false);
                    this.idsRegionConsulted.push(region.idRegiao)
                }
            });
        },

        numberOfDoses: {
            handler (newNumberDoses) {
                if(this.devideByRegion) {
                    this.regions[0].noPromotion = newNumberDoses.numberOfDosesWithoutPromotion.quantity;
                    this.regions[0].withPromotion = newNumberDoses.numberOfDosesWithPromotion.quantity;
                }

                this.regions.map((region) => {
                    if(region.open || this.devideByRegion){
                        FillUnidades.updateSummary(this, region);
                        this.controlColumns(region);
                        this.sumColumnDistribution(region);
                    }
                });

                if(this.devideByRegion) { this.validateHasDosesAvailable() }
            },
            deep: true,
        },

        editedVaccines(vaccines) {
            this.selectedVaccinesEdited = vaccines;
            this.validateHasDosesAvailable();
        },
    }
}
</script>

<style scoped>
.custom-width-actions-table{
    min-width: 30px;
}
.custom-tooltip {
    font-size: 14px;
}

.separatorBar {
    border-left: 1px solid #EBE9F1;
    height: 100%;
}
</style>
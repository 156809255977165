<template>
    <PrecificationBaseForm
        :busEvent="busEvent"
        :formContext="formContext"
    />
</template>

<script>
import dataPriceHelper from '@/views/custom-pages/Campanhas/EditCampanha/Forms/ServicesEdit/prepareDataPrices';
import PrecificationHelper from '@/views/custom-pages/Campanhas/Helpers/FormPrecificationHelper';
import PrecificationFormService from '@/views/custom-pages/Campanhas/Services/vaccinePricingFormService';
import PrecificationBaseForm from '@/views/custom-pages/Campanhas/BaseForms/PrecificationBaseForm.vue';
import { busEvent } from '@/main';

export default {
    components: {
        PrecificationBaseForm
    },

    props: {
        hasPromotion: {
            required: true,
            type: Boolean,
        },
        incentives: {
            required: true,
            type: Object|null
        },
        numberOfDoses: {
            required: true,
            type: Object|null
        },
        idVaccine: {
            required: true
        },
        vaccines: {
            required: true
        },
        editedVaccines: {
            required: true
        }
    },

    data() {
        return {
            busEvent,
            formContext: this,
            PrecificationHelper,
            hasNumberOfDoses: false,
            allIsDeactivated: false,
            priceEmptyCategory: false,
            noTypePrice: false,
            loadingCompanyType: true,
            hasDosesNoPromotion: true,
            hasDosesPromotion: true,
            showModalError: false,
            formPrecification: [],
            tiposEmpresaCategorias: [],
            hasIncentivePromotion: this.$props.hasPromotion,
            selectedIncentives: this.$props.incentives,
            selectedVaccine: this.$props.idVaccine,
            infosVaccineCampaign: this.$props.vaccines,
            selectedVaccinesEdited: this.$props.editedVaccines,
            hasDistributionDoses: false
        }
    },

    async mounted() {
        const { data } = await this.$http.get(this.$api.departamentoTipoEmpresa());

        this.tiposEmpresaCategorias = data;
        this.hasNumberOfDoses = true;
        this.hasDosesNoPromotion = false;
        this.hasDosesPromotion = false;
        this.makeFormPrecification();
        PrecificationHelper.setupFormConstruction(this.$props.numberOfDoses, this);

        this.fillform();

        busEvent.$on('saveCampanha', this.prepareDataSavePrecification);
        busEvent.$on('checkPrecificationLink', this.checkIfVaccineHasPrice);
        busEvent.$on('hasDistribution', this.handledistributionPrice);
    },

    beforeDestroy() {
        busEvent.$off('saveCampanha', this.prepareDataSavePrecification);
        busEvent.$off('checkPrecificationLink', this.checkIfVaccineHasPrice);
        busEvent.$on('hasDistribution', this.handledistributionPrice);
    },

    methods: {
        fillform() {
            const vaccinePrices = this.infosVaccineCampaign.filter(vaccine => vaccine.id_vacina == this.selectedVaccine)[0];

            if(!vaccinePrices) { return; }  

            if(this.hasIncentivePromotion) {
                const estimativaPromotion = vaccinePrices.estimativa.filter(estimativaIncentivo => estimativaIncentivo.incentivo.descricao != 'Normal')[0];

                if(estimativaPromotion) { 
                    const priceTypesCampaignPromotion = estimativaPromotion.campanha_tipo_preco.map(priceType => priceType.id_tipo_preco);

                    this.fillPricesForm(priceTypesCampaignPromotion, estimativaPromotion, 'priceWithPromotion');
                }
            }

            const estimativaNoPromotion = vaccinePrices.estimativa.filter(estimativaIncentivo => estimativaIncentivo.incentivo.descricao == 'Normal')[0];
            
            if(!estimativaNoPromotion) { return }
            
            const priceTypesCampaignNoPromotion = estimativaNoPromotion.campanha_tipo_preco.map(priceType => priceType.id_tipo_preco);

            this.fillPricesForm(priceTypesCampaignNoPromotion, estimativaNoPromotion, 'priceNoPromotion');
        },

        fillPricesForm(priceTypesCampaign, estimativas, typePromotion) {
            this.formPrecification.map(formPriceType => {
                if(priceTypesCampaign.includes(formPriceType.idTipoEmpresa)) {
                    dataPriceHelper.fillPromotionInputs(estimativas, formPriceType, typePromotion);
                }

                dataPriceHelper.notApplicableAll(formPriceType, typePromotion);
            });
        },

        makeFormPrecification() {
            let oldPrices = [];
            if(this.formPrecification){
                oldPrices = this.formPrecification;
                this.formPrecification = [];
            }

            if(!this.tiposEmpresaCategorias) {
                this.hasNumberOfDoses = false; 
                this.loadingCompanyType = false;
                this.noTypePrice = true;
                return; 
            }

            PrecificationHelper.makeTiposEmpresaCategoriasPrecification(this, oldPrices, true);

            this.loadingCompanyType = false;
        },

        prepareDataSavePrecification() {
            let precosToSave = [];

            if(PrecificationHelper.checkIfCanSavePrecification(this, busEvent)) { 
                this.emitEventPrepareDataVaccine(precosToSave);
                return; 
            }

            precosToSave = PrecificationHelper.preparePrecificationToSave(
                this.formPrecification, this.selectedIncentives
            );

            this.emitEventPrepareDataVaccine(precosToSave);
        },

        emitEventPrepareDataVaccine(precosToSave) {
            busEvent.$emit('vaccinePricingDataCampaignPrepare', {
                id_vacina: this.selectedVaccine,
                preco: precosToSave
            });
        },

        checkIfVaccineHasPrice(vaccineId) {
            if(this.selectedVaccine == vaccineId) {
                let hasPrice = false;

                hasPrice = this.formPrecification.some(vaccinePrice => { 
                    return PrecificationFormService.checkVaccineHasPrice(vaccinePrice.priceNoPromotion) || 
                        PrecificationFormService.checkVaccineHasPrice(vaccinePrice.priceWithPromotion);
                });

                busEvent.$emit('vaccineHasPrice', hasPrice);
            }
        },

        handledistributionPrice(hasDistribution) {
            if(this.selectedVaccine == hasDistribution.vaccineId && hasDistribution.hasDistribution) {
                this.hasDistributionDoses = true;
            }
        }
    },

    watch: {
        hasPromotion(value) {
            this.hasIncentivePromotion = value;
        },

        numberOfDoses: {
            handler (newValue) {
                if(!this.tiposEmpresaCategorias) { return; }

                PrecificationHelper.setupFormConstruction(newValue, this);
                this.fillform();
                PrecificationHelper.checkIfCanSavePrecification(this, busEvent);
            },
            deep: true,
        },

        idVaccine(idVaccine) {
            this.selectedVaccine = idVaccine;
        },

        vaccines(vaccines) {
            this.infosVaccineCampaign = vaccines;
        },

        editedVaccines(vaccines) {
            this.selectedVaccinesEdited = vaccines;
            PrecificationHelper.checkIfCanSavePrecification(this, busEvent);
        },

        incentives(incentives) {
            this.selectedIncentives = incentives;
        }
    }
}
</script>

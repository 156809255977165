<template>
  <div>
    <section v-if="drEditCampaign">
      <dr-edit-campaign />
    </section>

    <section v-if="arEditCampaign">
      <ar-edit-campaign />
    </section>
  </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import ArEditCampaign from "@/views/custom-pages/Campanhas/Edit/ArEditCampaign";
import DrEditCampaign from "@/views/custom-pages/Campanhas/EditCampanha/Edit-campaign";
import { setRegionConsulted } from '@core/utils/store/setStore';

export default {
  components: {
    ArEditCampaign,
    DrEditCampaign,
  },

  beforeMount() {
    setRegionConsulted(null);
  },

  created() {
    if(!this.drEditCampaign && !this.arEditCampaign) {
      this.$router.push({ name: 'misc-not-authorized' })
    }
  },

  data() {
    return {
      drEditCampaign : this.$can(actions.ATUALIZAR, subjects.CAMPANHAS_SESI_DR),
      arEditCampaign : this.$can(actions.ATUALIZAR, subjects.CAMPANHAS_SESI_UO),
    }
  },
}
</script>
export default {
    fillPromotionInputs(estimativa, formPriceType, type) {
        estimativa.campanha_tipo_preco.map(categoryPrice => {
            if(categoryPrice.id_tipo_preco == formPriceType.idTipoEmpresa) {
                const categoriesPriceType = categoryPrice.campanha_tipo_preco_categoria.map(category => {
                    return {
                        idCategoria: category.id_categoria,
                        value: category.valor_dose
                    };
                });

                formPriceType[type].map(categoryForm => {
                    const categoryPriceType = categoriesPriceType.find(priceType => priceType.idCategoria == categoryForm.idCategoria);

                    if(categoryPriceType) { 
                        categoryForm.value = categoryPriceType.value.toFixed(2); 
                    }
                });
            }
        });
    },

    notApplicableAll(formPriceType, type) {
        formPriceType[type].map(prices => {
            if(prices.value == '0,00') {
                prices.notApplicable = true;
            } else {
                prices.notApplicable = false;
                formPriceType.activeCompanyType = false;
            }
        });
    }
}
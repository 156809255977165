<template>
  <div v-if="getValidation" class="content-wrapper">
    <div v-if="loading">
      <b-col
          cols="12"
          class="text-center"
      >
        <b-spinner variant="custom" class="mb-1" ></b-spinner>
      </b-col>
    </div>

    <div v-if="!loading">
      <section>
        <page-header :screenName="form.nome_campanha" :linkItems="linkItems" />
      </section>

      <b-alert variant="warning" :show="getEmptyDistribution">
        <div class="alert-body">
          {{ errorMessage }}

          <p>
            <b-link :to="{ name: 'campanha-list' }">
              Voltar para a listagem de campanhas
            </b-link>
          </p>
        </div>
      </b-alert>

      <ConfirmModal
          itemIdentifier="campanha"
          :modalShow="modal.showModal"
          :typeModal="modal.typeModal"
          @confirmed="redirectToList"
          @canceled="closeModalConfirm"
      />

      <SucessoModal
          :modalShow="modalSucesso.showModal"
          :typeModal="modalSucesso.typeModal"
          :caseModal="modalSucesso.caseModal"
          @confirmed="redirectToList"
      />

      <AdminRegiaoModal
          :modalShow="modalError.showModal"
          :typeModal="modalError.typeModal"
          :titleModal="modalError.title"
          :infoText="modalError.infoText"
          @confirmed="closeModalErro"
      />
      <section v-if="!getEmptyDistribution">
        <b-tabs content-class="mt-0" nav-wrapper-class="no-margin">
          <b-tab
              v-for="data in vaccines"
              :title="data.nome_tecnico"
              :key="data.id_vacina"
              title-link-class="font-tab"
              title-item-class="custom-tab-style"
          >
            <main class="bg-white p-2">
              <b-row>
                <b-col lg="12" class="p-0">
                  <form-wizard
                      color="#2772C0"
                      :title="null"
                      :subtitle="null"
                      :hideButtons="true"
                      :startIndex="1"
                      class="adjusts-table mb-3"
                      ref="wizard"
                  >
                    <tab-content
                        title="Valores"
                        icon="feather icon-dollar-sign"
                    >
                      <vaccine-pricing
                          :idCampanha="idCampaign"
                          :idVacccine="data.id_vacina"
                      />
                    </tab-content>

                    <tab-content
                        title="Unidade"
                        icon="feather icon-disc"
                    >
                      <region-unit-distribution
                          :idCampanha="idCampaign"
                          :idVacccine="data.id_vacina"
                          :saveInfo="saveInfo"
                          @saveDistribution="handleSaveDistribution"
                      />
                    </tab-content>
                  </form-wizard>
                </b-col>
              </b-row>
            </main>
          </b-tab>
        </b-tabs>
      </section>

      <section class="custom-card-header mt-1">
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              <b-button
                  variant="custom-blue"
                  class="mr-2"
                  :disabled="savingInfosCampanha||disableSaving||disableSavingNewVaccine"
                  @click.prevent="() => {
                  saveInfo = true;
                }"
              >
                <feather-icon size="14" icon="CheckIcon" />&nbsp;
                Salvar
                <span v-if="saveInfo" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
              </b-button>

              <b-button
                  variant="outline-danger"
                  @click.prevent="openModalConfirm"
              >
                <feather-icon size="14" icon="XIcon" />&nbsp;
                Descartar Alterações
              </b-button>
            </div>
          </b-col>
        </b-row>
      </section>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import AdminRegiaoModal from '@/views/components/custom/modals/ModalShowError.vue'
import moment from 'moment'
import { busEvent } from "@/main";
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BSpinner,
  BRow,
  BTab,
  BTable,
  BTableSimple,
  BTabs,
  BTbody,
  BTd,
  BTr,
  BAlert
} from 'bootstrap-vue'
import VaccinePricing from './ArEdit/VaccinePricing.vue'
import RegionUnitDistribution from './ArEdit/RegionUnitDistribution.vue'

export default {
  components: {
    PageHeader,
    FormWizard,
    TabContent,
    BRow,
    BLink,
    BCol,
    BCard,
    BTabs,
    BTab,
    BCardText,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BTable,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BAlert,
    VaccinePricing,
    SucessoModal,
    ConfirmModal,
    AdminRegiaoModal,
    RegionUnitDistribution
},

  created() {
    if(this.$route.params.idCampanha) {
            localStorage.setItem('idCampanhaEdit', this.$route.params.idCampanha);
            this.idCampaign = this.$route.params.idCampanha;
    } else {
        this.idCampaign = parseInt(localStorage.getItem('idCampanhaEdit'));
    }

    this.validation = true
  },

  mounted() {
    busEvent.$on("disableButtonSave", (numberDoseError) => {
      this.disableSaving = numberDoseError.error;
    });

    busEvent.$on("disableButtonSaveNewVaccine", (booleanValue) => {
      this.disableSavingNewVaccine = booleanValue;
    });

    busEvent.$on("savingCampaign", (saving) => {
      this.savingInfosCampanha = saving;
    });
    if(this.validation) {
      this.findByCampaignId()
    }
  },

  data() {
    return {
      moment,
      idCampaign: null,
      validation: false,
      loading: false,
      errorMessage: '',
      savingInfosCampanha: false,
      disableSaving: false,
      disableSavingNewVaccine: false,
      linkItems: [
        {
          name: 'Campanhas',
          routeName: 'campanha-list'
        },
        {
          name: 'Editar Campanha',
          active: true
        }
      ],

      vaccines: [],
      campanha: {
        situacao: 'Rascunho',
        campanha_cronograma: {
          inicio: '',
        }
      },
      saveInfo: false,
      dataToSave: {
          vacinas: []
      },
      form: {
        nome_doenca: "Carregando doença...",
        nome_campanha: "Carregando campanha..."
      },
      modal: {
          showModal: false,
          typeModal: 'danger'
      },
      modalSucesso: {
          showModal: false,
          typeModal: 'success',
          caseModal: 'edicao'
      },
      modalError: {
          showModal: false,
          title: '',
          infoText: '',
          typeModal: 'erro-geral',
      },
      emptyDistribution: false
    }
  },

  methods: {
    async findByCampaignId() {
      this.loading = true;

      await this.$http.get(this.$api.getShowAndEditCampanha(this.idCampaign))
        .then(response => {
          const campaign = response.data;
          this.vaccines = campaign.vacina
          this.form.nome_campanha = campaign.descricao
          this.campanha.situacao = campaign.situacao
          this.form.nome_doenca = campaign.doenca.nome_tecnico
        })
        .catch(error => {
          this.listError(error.response)
        })

      this.loading = false;
    },

    handleInvalidId() {
      this.$swal({
        icon: 'warning',
        html: `Registro não encontrado.`,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: '',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.replace({ name: 'campanha-list' });
        }
      })
    },

    listError(response) {
      const status = response.status;
      const isStatus = status === 400 || status === 422

      this.emptyDistribution = true

      if(isStatus) {
        this.errorMessage = response.data.errors
      } else {
        this.errorMessage = 'Não foi possível realizar sua requisição. Entre em contato com o suporte.'
      }
    },

    handleSaveDistribution(dataDistribution){
        this.dataToSave.vacinas.push(dataDistribution);      
    },
    async saveDataAdminRegiao(){
        this.savingInfosCampanha = true;
        await this.$http.put(this.$api.campanhaParameterDistribuicaoDoses(this.idCampaign), this.dataToSave)
          .then(() => {
              this.openModalSucesso();
          }).catch(({ response: { status, data } }) => {
            this.savingInfosCampanha = false;
            if(status === 422){
              this.modalError.title = ''
              this.modalError.infoText = ''
              this.openModalErro('erro-geral');
            }

            if(status === 400){
              this.modalError.title = data.errors.erro[0].titulo
              this.modalError.infoText = data.errors.erro[0].msg
              this.openModalErro('erro-dose');
            }
          })
    },
    redirectToList(){
       this.$router.push({ name: 'campanha-list' });
    },
    openModalConfirm() {
        this.modal.showModal = true;
    },

    closeModalConfirm() {
        this.modal.showModal = false;
    },
    openModalSucesso() {
        this.modalSucesso.showModal = true;
    },

    closeModalSucesso() {
        this.modalSucesso.showModal = false;
    },
    openModalErro(typeError) {
        this.modalError.typeModal = typeError;
        this.modalError.showModal = true;
    },

    closeModalErro() {
        this.modalError.showModal = false;
         this.saveInfo = false;
    },
  },

  computed: {
    getValidation() {
      return this.validation
    },

    getEmptyDistribution() {
      return this.emptyDistribution
    }
  },
  watch: {
    saveInfo(value) {
      if(value) {
        this.dataToSave.vacinas = [];
      }
    },
    dataToSave: {
      handler() {
          if(this.dataToSave.vacinas.length === this.vaccines.length){
            this.saveDataAdminRegiao();
          }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  @import "@/assets/scss/custom/edit-campaign.scss";
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import "@/assets/scss/variables/_variables.scss";
  @import "@/assets/scss/custom/custom-form-wizard.scss";
</style>
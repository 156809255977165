<template>
    <div>
        <CustomAlert v-if="liberarUnidades" variant="warning" message="É preciso definir se este departamento utiliza ou não regiões para distribuir as doses" :show="showAlert"/>
        <form-wizard
            ref="formDistributionEditRef"
            color="#2772C0"
            errorColor="#d9534f"
            :title="null"
            :subtitle="null"
            finish-button-text="Submit"
            back-button-text="Previous"
            class="steps-transparent"
            @on-change="changeTab"
        >
            <tab-content title="Valores" icon="feather icon-dollar-sign" :beforeChange="() => blockAdvanceStep()"> 
                <VaccinePricingCampaignEditForm 
                    :hasPromotion="hasPromotion"
                    :incentives="selectedIncentives"
                    :numberOfDoses="selectedNumberOfDoses"
                    :idVaccine="idVaccine"
                    :vaccines="infosVaccineCampaign"
                    :editedVaccines="selectedVaccineEdited"
                    @errorValidationPrecification="handleErrorValidationPrecification"
                />
            </tab-content>
            <tab-content :class="!hasRegion ? 'd-none' : ''" title="Regiões" icon="feather icon-database" :beforeChange="() => blockAdvanceStep()">
                <DoseDistribuitionRegionCampginEditForm 
                    :hasPromotion="hasPromotion"
                    :incentives="selectedIncentives"
                    :numberOfDoses="selectedNumberOfDoses"
                    :idVaccine="idVaccine"
                    :vaccines="infosVaccineCampaign"
                    :editedVaccines="selectedVaccineEdited"
                    :situationCampaign="situationCampaignSelected"
                    @regionsDistributeUnidade="handleRegionDistributionUnidade"
                />
            </tab-content>
            <tab-content title="Unidade" icon="feather icon-disc">
                <DoseDistributionOperatingUnitCampaignEditForm
                    :hasPromotion="hasPromotion"
                    :vaccines="infosVaccineCampaign"
                    :editedVaccines="selectedVaccineEdited"
                    :situationCampaign="situationCampaignSelected"
                    :regionsSelected="selectedRegioes"
                    :numberOfDoses="selectedNumberOfDoses"
                    :idVaccine="idVaccine"
                    :incentives="selectedIncentives"
                />
            </tab-content>
            <template slot="footer" slot-scope="props">
                <div v-if="!showButtom"></div>
                <b-button 
                    v-else
                    :disabled="disabledButtonWhenError || disabledButtonStep"
                    id="advance-stage" 
                    class="mr-2" 
                    @click="proximaPagina(props)" 
                    variant="custom-blue"
                >
                    <div v-if="!liberarUnidades">
                        <feather-icon icon="ArrowRightIcon" class="mr-50"/>
                        <span class="align-middle">Avançar</span>
                    </div>
                    <div v-else>
                        <feather-icon icon="CheckIcon" class="mr-50"/>
                        <span class="align-middle">Liberar para Unidades</span>
                    </div>
                </b-button>
            </template>
        </form-wizard>
    </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { getInfoUserDepartamento } from "@core/utils/store/getStore";
import CustomAlert from '@/views/components/custom/timed-alert/CustomAlert.vue';

import VaccinePricingCampaignEditForm from '../Forms/VaccinePricingCampaignEditForm.vue';
import DoseDistribuitionRegionCampginEditForm from '../Forms/DoseDistribuitionRegionCampaignEditForm.vue';
import DoseDistributionOperatingUnitCampaignEditForm from '../Forms/DoseDistributionOperatingUnitCampaignEditForm.vue';

import { busEvent } from '@/main';

export default {
    components: {
        FormWizard, TabContent, BButton, CustomAlert,
        VaccinePricingCampaignEditForm, DoseDistribuitionRegionCampginEditForm,
        DoseDistributionOperatingUnitCampaignEditForm
    },

    props: {
        numberOfDoses: {
            required: true,
            type: Object|null
        },
        incentives: {
            required: true,
            type: Object|null
        },
        idVaccine: {
            required: true
        },
        vaccines: {
            required: true
        },
        editedVaccine: {
            required: true
        },
        situationCampaign: {
            required: true,
            type: String
        }
    },

    data() {
        return {
            liberarUnidades: false,
            showAlert: false,
            hasRegion: getInfoUserDepartamento('divisao_por_regiao') ? true : false,
            showButtom: true, 
            disabledButtonWhenError: true,
            disabledButtonStep: false,
            selectedIncentives: this.$props.incentives,
            selectedNumberOfDoses: this.$props.numberOfDoses,
            selectedVaccine: this.$props.idVaccine,
            infosVaccineCampaign: this.$props.vaccines,
            hasPromotion: false,
            selectedRegioes: null,
            hasRegionDistribution: false,
            situationCampaignSelected: this.$props.situationCampaign,
            selectedVaccineEdited: this.$props.editedVaccine
        }
    },

    mounted() {
        let sheet = window.document.styleSheets[0];
        if(!this.hasRegion){
            sheet.insertRule('.wizard-nav.wizard-nav-pills > li:nth-of-type(2) {display: none;}', sheet.cssRules.length);
        } else{
            sheet.insertRule('.wizard-nav.wizard-nav-pills > li:nth-of-type(2) {display: block;}', sheet.cssRules.length);
        }
        
        this.fillIncentive();
        busEvent.$on('blockButtonStep', this.handleBlockButtonStep);
        busEvent.$on('hasDistribution', this.handleHasDistribution);
    },

    beforeDestroy() {
        busEvent.$off('blockButtonStep', this.handleBlockButtonStep);
        busEvent.$off('hasDistribution', this.handleHasDistribution);
    },

    methods: {
        proximaPagina(prop) {
            prop.nextTab();
            if(!this.hasRegion && prop.activeTabIndex == 0) {
                prop.nextTab();
            }
        },

        changeTab(_prevIndex, nextIndex) {
            if(nextIndex == 0) {
                this.liberarUnidades = false;
                this.disabledButtonStep = false;
                this.showButtom = true;
            }
            if(nextIndex == 1) {
                busEvent.$emit('checkHasDistributionRegion', this.selectedVaccine);

                this.disabledButtonStep = true;
                if(this.hasRegionDistribution) {
                    this.disabledButtonStep = false;
                }
                this.liberarUnidades = true;
                this.showButtom = true;
            }
            if(nextIndex == 2) {
                this.showButtom = false;
            }
        },

        fillIncentive() {
            const promotion = this.selectedIncentives.filter(incentive => incentive.nome !== 'Normal');

            if(promotion.length > 0) {
                this.hasPromotion = true;
            }
        },

        handleErrorValidationPrecification(error) {
            this.disabledButtonWhenError = error;

            if(this.situationCampaignSelected != 'Rascunho') {
                busEvent.$emit('disableButtonSave', { error });
            }
        },

        handleBlockButtonStep(data) {
            if(data.idVaccine == this.selectedVaccine) {
                this.disabledButtonStep = data.block;
            }
        },

        blockAdvanceStep() {
            return !(this.disabledButtonWhenError || this.disabledButtonStep);
        },

        handleRegionDistributionUnidade(regions) {
            this.selectedRegioes = regions[0];

            if(this.$refs.formDistributionEditRef.activeTabIndex == 0) { return; } 

            let onlyReservation = false;

            if(regions[0].length == 0) {
                onlyReservation = true;
            }

            if(regions[1] || onlyReservation) {
                this.disabledButtonStep = true;
                busEvent.$emit('canSaveSchedulePrice', [false, this.selectedVaccine]);
            } else {
                this.disabledButtonStep = false;
                busEvent.$emit('canSaveSchedulePrice', [true, this.selectedVaccine]);
            }
        },

        handleHasDistribution(distribution) {
            if(distribution.vaccineId == this.selectedVaccine) {
                this.hasRegionDistribution = distribution.hasDistribution;
            }
        }
    },

    watch: {
        numberOfDoses: {
            handler (numberOfDoses) {
                this.selectedNumberOfDoses = numberOfDoses
            },
            deep: true
        },

        incentives(incentives) {
            this.selectedIncentives = incentives;

            this.fillIncentive();
        },

        idVaccine(idVaccine) {
            this.selectedVaccine = idVaccine;
        },

        vaccines(vaccines) {
            this.infosVaccineCampaign = vaccines;
        },

        editedVaccine(vaccines) {
            this.selectedVaccineEdited = vaccines;
        }
    }
}
</script>

<style lang="scss">
    @import "@/assets/scss/variables/_variables.scss";

    .wizard-nav.wizard-nav-pills > li > a > span {
        margin-bottom: 12px;
        text-align: left;
        width: 130px;
    }

    .wizard-nav.wizard-nav-pills > li > a > div.checked > i {
        color: $custom-blue !important;
    }

    .wizard-nav.wizard-nav-pills > li > a > div.checked {
        background-color: rgba(37, 95, 182, 0.08) !important;
    }

    .wizard-nav.wizard-nav-pills > li:nth-child(1)::after {
        content: "Precificação de Doses";
        position: relative;
        bottom: 18px;
        left: 28px;
        color: #6E6B7B;
        font-size: 12px;
        font-weight: normal;
        word-break: keep-all;
        cursor: pointer
    }

    .wizard-nav.wizard-nav-pills > li:nth-child(1n+2)::after {
        content: "Distribuição de Doses";
        position: relative;
        bottom: 18px;
        left: 28px;
        color: #6E6B7B;
        font-size: 12px;
        font-weight: normal;
        word-break: keep-all;
        cursor: pointer
    }

    .steps-transparent .wizard-tab-content, .steps-transparent .wizard-card-footer {
        box-shadow: none;
        padding-top: 0
    }

    .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
        box-shadow: none !important;
    }
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"warning","show":_vm.emptyPrecification}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("A precificação não foi definida no cadastro da campanha, informe o departamento da região ou entre em contato com o suporte.")])])]),(_vm.loadingCompanyType)?_c('div',{staticClass:"d-flex justify-content-center mt-4"},[_vm._m(0)]):_c('b-row',[_c('b-col',{attrs:{"lg":"7"}},[_c('b-form',[_c('app-collapse',_vm._l((_vm.formPrecification),function(price,priceIndex){return _c('app-collapse-item',{key:priceIndex,staticClass:"mb-2",attrs:{"isVisible":priceIndex == 0 && price.priceNoPromotion.length > 0,"title":price.companyType}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"text-custom-blue mb-0"},[_vm._v(_vm._s(price.companyType))])]),_c('section',{staticClass:"pricingForm pt-0 pb-1 px-1 max-width-form"},[(price.priceNoPromotion.length > 0)?_c('b-row',_vm._l((price.priceNoPromotion),function(priceInputsNoPromotion,index){return _c('b-col',{key:index,class:{'pr-0': _vm.HelperLayout.adjustLayoutRight(price.priceNoPromotion, index)},attrs:{"sm":"12","md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":'price-'+priceInputsNoPromotion.category}},[_vm._v(_vm._s(priceInputsNoPromotion.category))]),_c('b-input-group',[_c('b-input-group-prepend',[_c('div',{class:{
                                                        'bg-custom-blue d-flex align-items-center custom-append-input': true,
                                                        'disabled-append': priceInputsNoPromotion.notApplicable
                                                    }},[_c('b-img',{attrs:{"src":_vm.Ricon,"alt":"Icone que representa o real"}})],1)]),_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.moneyMask),expression:"moneyMask"}],class:{
                                                    'border-color-input': true,
                                                    'border-color-input-disabled': true
                                                },attrs:{"id":'price-'+priceInputsNoPromotion.category,"placeholder":"00,00","disabled":true},on:{"keydown":function($event){$event.key === '-' && $event.preventDefault()}},model:{value:(priceInputsNoPromotion.value),callback:function ($$v) {_vm.$set(priceInputsNoPromotion, "value", $$v)},expression:"priceInputsNoPromotion.value"}})],1)],1)],1)}),1):_vm._e(),(_vm.hasIncentivePromotion && price.priceWithPromotion.length > 0)?_c('b-row',{staticClass:"background-custom-blue p-1"},[_c('h5',{staticClass:"w-100"},[_vm._v("Com fomento")]),_vm._l((price.priceWithPromotion),function(priceInputsWithPromotion,index){return _c('b-col',{key:index,class:{
                                        'pr-0': _vm.HelperLayout.adjustLayoutRightWithPromotion(price.priceWithPromotion, index), 
                                        'pl-0': _vm.HelperLayout.adjustLayoutLeft(price.priceWithPromotion, index)
                                    },attrs:{"sm":"12","md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":'price-'+priceInputsWithPromotion.category}},[_vm._v(_vm._s(priceInputsWithPromotion.category))]),_c('b-input-group',[_c('b-input-group-prepend',[_c('div',{class:{
                                                        'bg-custom-blue d-flex align-items-center custom-append-input': true,
                                                        'disabled-append': priceInputsWithPromotion.notApplicable
                                                    }},[_c('b-img',{attrs:{"src":_vm.Ricon,"alt":"Icone que representa o real"}})],1)]),_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.moneyMask),expression:"moneyMask"}],class:{
                                                    'border-color-input': true,
                                                    'border-color-input-disabled': true
                                                },attrs:{"id":'price-'+priceInputsWithPromotion.category,"placeholder":"00,00","disabled":true},on:{"keydown":function($event){$event.key === '-' && $event.preventDefault()}},model:{value:(priceInputsWithPromotion.value),callback:function ($$v) {_vm.$set(priceInputsWithPromotion, "value", $$v)},expression:"priceInputsWithPromotion.value"}})],1)],1)],1)})],2):_vm._e()],1)],2)}),1)],1)],1),_c('b-col',{staticClass:"separator ml-2 pl-2 pb-5",attrs:{"lg":"4"}},[_c('SummaryPricing',{attrs:{"formPrecification":_vm.formPrecification,"hasIncentivePromotion":_vm.hasIncentivePromotion,"hasNumberOfDoses":_vm.hasDosesNoPromotion}})],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border text-custom-blue",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])}]

export { render, staticRenderFns }
<template>
    <b-row>
        <b-col lg="12" md="12" sm="12">
            <div v-if="loadingData" class="text-center">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </b-col>
        <b-col lg="7" md="8" sm="10" :class="loadingData ? 'd-none' : ''">
            <b-form>
                <app-collapse accordion>
                    <app-collapse-item
                        v-for="(region, index) in regions"
                        :key="index"
                        class="mb-1"
                        title="unidade"
                        :isVisible="openFirstAcorddion"
                        @visible="loadUnidades($event, region, index)"
                    >
                        <template slot="header">
                            <h4 class="text-custom-blue mb-0">{{ region.descricao }}</h4>
                        </template>

                        <div v-if="!region.unidades" class="text-center">
                            <div class="spinner-border text-custom-blue" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>

                        <b-table
                            v-else
                            ref="tableDosesUnit"
                            id="table-doses-unit"
                            responsive
                            :stickyColumn="true"
                            :fields="region.fields"
                            :items="region.unidades"
                            no-local-sorting
                            :no-sort-reset="true"
                            tbody-tr-class="custom-center-content"
                            table-class="custom-table"
                        >
                            <template #cell(actions)="row">
                                <feather-icon v-if="row.item.tooltip"
                                    v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
                                    title="Espaço para reserva de doses a serem distribuídas futuramente."
                                    icon="InfoIcon" 
                                    size="18"
                                    color="#2772C0"
                                />
                            </template>
                            <template #cell(descricao)="row">
                                <span class="custom-font-bold">{{ row.item.descricao }}</span>
                            </template>
                            <template #cell(noPromotion)="row">
                                <b-form-input 
                                    v-model="row.item.noPromotion"
                                    v-mask="'##########'"
                                    autocomplete="off"
                                    class="custom-input-number-doses"
                                    @input="() => {
                                        CalcTable.sumAllRowTable(row.item);
                                        sumColumnDistribution(region);
                                        validateHasDosesAvailable();
                                    }"
                                    placeholder="---"
                                />
                            </template>
                            <template #cell(withPromotion)="row">
                                <b-form-input 
                                    v-model="row.item.withPromotion"
                                    v-mask="'##########'"
                                    autocomplete="off"
                                    class="custom-input-number-doses"
                                    @input="() => {
                                        CalcTable.sumAllRowTable(row.item);
                                        sumColumnDistribution(region);
                                        validateHasDosesAvailable();
                                    }"
                                    placeholder="---"
                                />
                            </template>
                            <template #cell(totalDoses)="row">
                                <span class="custom-font-bold m-0">{{ row.item.totalDoses ? row.item.totalDoses : '---' }}</span>
                            </template>
                        </b-table>
                    </app-collapse-item>
                </app-collapse>
            </b-form>
        </b-col>
        <b-col lg="4" md="4" sm="2" id="doseSectionUnit" class="separatorBar ml-2 pl-2 pb-5" :class="loadingData ? 'd-none' : ''">
            <DoseSummary 
                :dosesNumber="doses"
                :hasIncentive="hasIncentivePromotion"
            />
        </b-col>
    </b-row>
</template>

<script>
import { BTable, BForm, BRow, BCol, BFormInput } from 'bootstrap-vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import DoseSummary from '@/views/components/custom/DosesSummary/DosesSummaryregionUnit.vue';
import ErrorNumberOfDoses from '@/views/components/custom/modals/ModalErrorNumberOfDoses.vue';
import CalcTable from '@/views/custom-pages/Campanhas/Services/CalculatetableDoses';
import FillUnidades from '@/views/custom-pages/Campanhas/Edit/Services/FillDistributeRegionsUnidade';
import TableFieldControl from '@/views/custom-pages/Campanhas/Services/TableFieldControl';
import { busEvent } from "@/main";
import followScreen from '@/views/components/custom/DosesSummary/followScreen';


export default {
    components: {
        BTable, BForm, BRow, BCol, BFormInput,
        AppCollapse, AppCollapseItem, DoseSummary,
        ErrorNumberOfDoses
    },

    props: {
        saveInfo: {
            required: true,
            type: Boolean
        },
        idCampanha: {
            required: true,
            type: Number|null
        },
        idVacccine: {
            required: true,
            type: Number|null
        },
    },

    data() {
        return {
            regions: [],
            units: [],
            viewRegion: false,
            savedDistribution: false,
            campaignId: this.$props.idCampanha,
            selectedIdVaccine: this.$props.idVacccine,
            hasIncentivePromotion: true,
            CalcTable,
            countUnitRegionSaved: 0,
            doses: {
                totalDosesWithPromotion: 0,
                totalDosesWithoutPromotion: 0,

                reservationWithPromotion: 0,
                reservationWithoutPromotion: 0,

                totalDistributedDosesWithPromotion: 0,
                totalDistributedDosesWithoutPromotion: 0,

                controlDosesWithPromotion: 0,
                controlDosesWithoutPromotion: 0,
            },
            modalErrorDoses: {
                showModal: false,
                type: 'rascunho-unidade'
            },
            loadingData: false,
            countData: 0,
            firstOpen: true,
            openFirstAcorddion: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', followScreen.verifyScroll(false))
        this.setupRegions();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', followScreen.verifyScroll(false))
    },
    methods: {
        validateHasDosesAvailable(emitError = true) {
            let allDosesAvailable = 0;
            let error = false;

          const hasNegativeDose = this.regions.some((region) => {
            if (region && region.dosesSummary)
            {
                if(
                    region.dosesSummary.controlDosesWithPromotion < 0 ||
                    region.dosesSummary.controlDosesWithoutPromotion < 0
                ){
                    return true;
                }
                allDosesAvailable +=
                region.dosesSummary.controlDosesWithPromotion +
                region.dosesSummary.controlDosesWithoutPromotion;
            }
          });

          if (hasNegativeDose) {
            this.emitErrorDoses(true, emitError);
            return;
          }

          if (allDosesAvailable == 0) {
            error = true;
          
            }

            busEvent.$emit("canSaveScheduleDistributionUnidade", [
                error,
                this.selectedVaccine,
            ]);

            if (this.situationCampaignSelected != "Rascunho") {
                this.emitErrorDoses(!error, emitError);
            } else {
                let errorRascunho = false;

                if (allDosesAvailable < 0) {
                    errorRascunho = true;
                }

                this.emitErrorDoses(errorRascunho, emitError);
            }

            allDosesAvailable = 0;
        },
       
        emitErrorDoses(error) {
          busEvent.$emit("disableButtonSave", { error });
        },
        
        setupRegions(){
        this.$http.get(this.$api.adminRegiaoUnidadesCampanhaVacina(this.campaignId, this.selectedIdVaccine))
            .then(response => {
            response.data.filter((regiao) => {
                let totalDosesPerRegion = 0;
                regiao.campanha_vacina_unidade.filter((unidade) => {
                    unidade.id_incentivo = regiao.id_incentivo;
                    totalDosesPerRegion += unidade.quantidade
                    this.units.push(unidade)
                });
                
                let r = this.regions.filter((region) => region.idRegiao == regiao.id_regiao);
                if(!r.length){
                    let region = {
                        descricao: regiao.descricao,
                        idRegiao: regiao.id_regiao,
                        noPromotion: null,
                        withPromotion: null,
                        reservationWithPromotion: null,
                        reservationNoPromotion: null,
                        totalDoses: null,
                        edit: true,
                    }
                    
                    if(regiao.id_incentivo == 1){
                        region.noPromotion = totalDosesPerRegion + regiao.reserva;
                        region.reservationNoPromotion = regiao.reserva;
                    }
                        if(regiao.id_incentivo == 2){
                        region.withPromotion = totalDosesPerRegion + regiao.reserva;
                        region.reservationWithPromotion = regiao.reserva;
                    }
                    
                    this.regions.push(region);
                    this.countData += 1;
                }else{
                    if(regiao.id_incentivo == 1){
                        r[0].noPromotion = totalDosesPerRegion + regiao.reserva;
                        r[0].reservationNoPromotion = regiao.reserva;
                    }
                        if(regiao.id_incentivo == 2){
                        r[0].withPromotion = totalDosesPerRegion + regiao.reserva;
                        r[0].reservationWithPromotion = regiao.reserva;
                    }
                }

                
            });
            
        });
        this.openFirstAcorddion = true;
        },
        loadUnidades(open, region, index) {
            FillUnidades.updateSummary(this, region);
            this.controlColumns(region, index);
            this.fillSumary(region, open);
            this.sumColumnDistribution(region);
            
            this.regions.map(r => {
                r.open = false;
            });

            region.open = true;

            if(open && !region.hasOwnProperty('unidades')) {
                this.$http.get(this.$api.unidade(), { params: {id_regiao: region.idRegiao, ativo: true}}).then(({ data }) => {
                    this.regions.map((regionUnidade, indexRegion) => {
                        if(regionUnidade.idRegiao == region.idRegiao) {
                            FillUnidades.fillUnidadesByRegion(this, regionUnidade, data, this.units);
                            this.controlColumns(regionUnidade, indexRegion);      
                            this.fillSumary(regionUnidade);
                        }
                    })
                });
            }
        },
        setupUnits(open, first, index){
            if(open && first){
                return true;
            }
            if(open && !first && index==0){
                return true;
            }
        },
        fillSumary(region, open = true) {
            if(!open) { return }

            if(region.hasOwnProperty('dosesSummary')) {
                this.doses.totalDosesWithPromotion = region.dosesSummary.totalDosesWithPromotion;
                this.doses.totalDosesWithoutPromotion = region.dosesSummary.totalDosesWithoutPromotion;
                this.doses.controlDosesWithPromotion = region.dosesSummary.controlDosesWithPromotion;
                this.doses.controlDosesWithoutPromotion = region.dosesSummary.controlDosesWithoutPromotion;

                this.doses.totalDistributedDosesWithPromotion = region.dosesSummary.totalDistributedDosesWithPromotion;
                this.doses.totalDistributedDosesWithoutPromotion = region.dosesSummary.totalDistributedDosesWithoutPromotion;

                this.doses.reservationWithPromotion = region.dosesSummary.reservationWithPromotion;
                this.doses.reservationWithoutPromotion = region.dosesSummary.reservationWithoutPromotion;
            }
        },

        controlColumns(region, index) {
            if(region.hasOwnProperty('fields')) {
                TableFieldControl.removePromotionNoIncentive(this.hasIncentivePromotion, region.fields);
                TableFieldControl.removePromotionDoses(region.withPromotion, region.fields);
                TableFieldControl.removeWithoutPromotionDoses(region.noPromotion, region.fields);
                if(this.$refs.tableDosesUnit) {
                    if(this.$refs.tableDosesUnit[index]) {
                        this.$refs.tableDosesUnit[index].refresh();
                    }
                }
            }
        },

        sumColumnDistribution(region) {
            if(region.hasOwnProperty('unidades')) {
                CalcTable.sumAllColumTable(region.dosesSummary, region.unidades);
                this.fillSumary(region);
            }
        },

        prepareUnitDataToSave(){

            let regionsToSave = [];

            let regionsEdited = this.regions.filter(region => region.edit)

            regionsEdited.map(region => {
                let unitsEdit = [];

                if(region.unidades){
                    region.unidades.map(unit => {
                        if(parseInt(unit.idUnidade) > 0){
                            this.prepareUnitsFromRegion(unit, unitsEdit);
                        }else{
                            region.reservationNoPromotion = unit.noPromotion
                            region.reservationWithPromotion = unit.withPromotion
                        }
                    })
                }

                regionsToSave.push({
                    id_regiao: region.idRegiao,
                    reserva_com_fomento:  parseInt(region.reservationWithPromotion),
                    reserva_sem_fomento:  parseInt(region.reservationNoPromotion),
                    unidades: unitsEdit
                })
            })

            return {
                id_vacina: this.selectedIdVaccine,
                regioes: regionsToSave
            }
        },

        prepareUnitsFromRegion(unit, unitsEdit) {
            if(unit.editNoPromotion){
                unitsEdit.push({
                    id_unidade: unit.idUnidade,
                    quantidade: unit.noPromotion ? unit.noPromotion : 0,
                    id_incentivo: 1,
                });
            }else{
                if(parseInt(unit.noPromotion) > 0){
                    unitsEdit.push({
                        id_unidade: unit.idUnidade,
                        quantidade:  parseInt(unit.noPromotion),
                        id_incentivo: 1,
                    });
                }
            }

            if(unit.editWithPromotion){
                unitsEdit.push({
                    id_unidade: unit.idUnidade,
                    quantidade:  unit.withPromotion ? unit.withPromotion : 0,
                    id_incentivo: 2,
                });
            }else{
                if(parseInt(unit.withPromotion) > 0){
                    unitsEdit.push({
                        id_unidade: unit.idUnidade,
                        quantidade:  parseInt(unit.withPromotion),
                        id_incentivo: 2,
                    });
                }
            }
        }
    },

    watch: {
        saveInfo(value){
            if(value){
                this.$emit('saveDistribution', this.prepareUnitDataToSave());
            }
        },
        idVacccine(idVaccine) {
            this.selectedIdVaccine = idVaccine;
        },
        countData(newValue) {
            if(newValue){
                this.openFirstAcorddion = true;
                this.regions.map(region => {
                    this.loadUnidades(true, region, 0)
                })
            }
        }
    }
}
</script>

<style scoped>
.custom-width-actions-table{
    min-width: 30px;
}

.custom-tooltip {
    font-size: 14px;
}

.separatorBar {
    border-left: 1px solid #EBE9F1;
    height: 100%;
}
</style>
<template>
    <section class="doseQuantificationEdit mt-2">
        <b-row class="w-100">
            <b-col lg="9" md="8" sm="7">
                <b-row v-for="(vacina, index) in formVacinasDoses"
                    :key="index"
                >
                    <b-col class="mt-1 min-with-vaciine-name">
                        <p class="font-weight-custom text-dark m-0">Quantidade</p>
                        <p class="font-weight-custom text-dark text-truncate">{{ vacina.nameVacina }}</p>
                    </b-col>
                    <b-col class="pr-0 mb-1">
                        <b-form-group
                            label="Doses sem Fomento"
                            label-for=" dosesWithoutPromotion"
                        >
                            <b-form-input
                                :state="vacina.errorWithoutPromotion ? false:null"
                                autocomplete="off" 
                                v-mask="'##########'"
                                @keypress="$helpers.doNotAllowZeroValue($event, vacina.numberOfDosesWithoutPromotion, 'quantity')"
                                @input="() => {
                                    sumOfDoses(),
                                    emitEventDisableButtonSaveNewVaccine(),
                                    emitEventNumberDoses()
                                }"
                                v-model="vacina.numberOfDosesWithoutPromotion.quantity"
                                id="dosesWithoutPromotion"
                                placeholder="Quantidade de doses sem fomento"
                            />
                            <small v-if="vacina.errorWithoutPromotion" class="text-danger">
                                {{ vacina.errorWithoutPromotion }}
                            </small>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="checkPromotion()">
                        <b-form-group
                            label="Doses com Fomento"
                            label-for="dosesWithPromotion"
                        >
                            <b-form-input
                                :state="vacina.errorWithPromotion ? false:null"
                                autocomplete="off" 
                                v-mask="'##########'"
                                @keypress="$helpers.doNotAllowZeroValue($event, vacina.numberOfDosesWithPromotion, 'quantity')"
                                @input="() => {
                                    sumOfDoses(true),
                                    emitEventDisableButtonSaveNewVaccine(),
                                    emitEventNumberDoses()
                                }"
                                v-model="vacina.numberOfDosesWithPromotion.quantity"
                                id="dosesWithPromotion"
                                placeholder="Quantidade de doses com fomento"
                            />
                            <small v-if="vacina.errorWithPromotion" class="text-danger">
                                {{ vacina.errorWithPromotion }}
                            </small>
                        </b-form-group>
                    </b-col>
                    <b-col class="d-flex align-items-center pl-0 min-width-trash-button">
                        <b-button
                            variant="flat-dark"
                            class="btn-icon rounded-circle"
                            @click="removeVacina(index)"
                        >
                            <feather-icon
                                icon="Trash2Icon"
                                size="18"
                                class="text-custom-blue"
                            />
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

            <b-col lg="2" md="3" sm="4" class="separator pl-3">
                <div v-if="formVacinasDoses.length > 1">
                    <div 
                        class="vacinasTotal mb-1"
                        v-for="vaccine in formVacinasDoses" 
                        :key="vaccine.controlFormId"
                    >
                        <p class="font-weight-custom text-dark m-0">{{ vaccine.nameVacina }}</p>
                        <p class="m-0">Total: 
                            <label class="text-custom-blue m-0">
                                {{ addAllDoses(vaccine.numberOfDosesWithPromotion.quantity, vaccine.numberOfDosesWithoutPromotion.quantity) }}
                            </label>
                        </p>
                    </div>
                </div>
                <div v-if="formVacinasDoses.length != 0" class="generalTotal">
                    <p class="font-weight-custom text-dark m-0">Total campanha</p>
                    <p class="m-0">
                        Doses sem fomento: 
                        <label class="text-custom-blue m-0">{{ totalNumberOfDosesWithoutPromotion }}</label>
                    </p>
                    <p v-if="checkPromotion()" class="m-0">
                        Doses com fomento: 
                        <label class="text-custom-blue m-0">{{ totalNumberOfDosesWithPromotion }}</label>
                    </p>
                    <p class="text-custom-blue font-weight-custom text-dark m-0">
                        Total: {{  totalNumberOfDosesWithPromotion + totalNumberOfDosesWithoutPromotion }}
                    </p>
                </div>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import { BRow, BCol, BForm, BFormInput, BFormGroup, BButton } from 'bootstrap-vue';

import { busEvent } from '@/main';

import  DoseQuantificationService from '../../Services/DoseQuantificationFormService';

export default {
    components: {
        BRow, BCol, BForm, BFormInput, BFormGroup, BButton
    },

     data() {
        return {
            formVacinasDoses: [],
            vaccinesAmountSelectedCampaign: [],
            selectedIncentives: null,
            totalNumberOfDosesWithoutPromotion: 0,
            totalNumberOfDosesWithPromotion: 0,
        }
    },

    mounted() {
        busEvent.$on('vaccinesEdited', (vaccines) => {
            this.createFormNumberOfDoses(vaccines);
            this.emitEventNumberDoses();
        });

        busEvent.$on('dataCampanha', (dataCampanha) => {
            this.selectedIncentives = [];
            this.vaccinesAmountSelectedCampaign = [];

            this.selectedIncentives = dataCampanha.incentives;
            this.vaccinesAmountSelectedCampaign = dataCampanha.campanha.campanha_vacina;
        });

        busEvent.$on('saveCampanha', () => {
            DoseQuantificationService.prepareDataDoseQuantification(busEvent, this.formVacinasDoses);
        });
    },

    methods: {
        createFormNumberOfDoses(vaccines) {
            this.formVacinasDoses.map((vaccineForm, index) => {
                const removedVaccine = vaccines.find(vaccine => vaccineForm.id_vacina === vaccine.id_vacina);

                if(!removedVaccine) {
                    this.formVacinasDoses.splice(index, 1);
                }
            });

            vaccines.map(vaccine => {
                const vaccineExists = this.formVacinasDoses.find(vaccineForm => vaccineForm.id_vacina == vaccine.id_vacina);

                if(vaccineExists) {return} 

                if(this.selectedIncentives) {
                    const incentivePromotion = this.selectedIncentives.find(incentive => incentive.nome !== 'Normal');
                    const incentiveNoPromotion = this.selectedIncentives.find(incentive => incentive.nome === 'Normal');
    
                    this.formVacinasDoses.push(
                        {
                            id_vacina: vaccine.id_vacina,
                            nameVacina: vaccine.nome_tecnico,
                            numberOfDosesWithoutPromotion: {
                                incentive: incentiveNoPromotion ? incentiveNoPromotion.id_incentivo : null,
                                quantity: null
                            },
                            numberOfDosesWithPromotion: {
                                incentive: incentivePromotion ? incentivePromotion.id_incentivo : null ,
                                quantity: null
                            },
                        }
                    )
                }
            })

            this.sumOfDoses(true);
            this.sumOfDoses();

            this.fiilAmountDoses();
        },

        fiilAmountDoses() {
            this.formVacinasDoses.map(vaccine => {
                const vaccineSelectedCampaign = this.vaccinesAmountSelectedCampaign
                    .find(vaccineSelected => vaccineSelected.id_vacina == vaccine.id_vacina);
                
                if(!vaccineSelectedCampaign) { return; }

                const quantities = { quantityWithoutPromotion: null, quantityWithPromotion: null};
                
                vaccineSelectedCampaign.estimativa.map(estimatives => {
                    if(estimatives.incentivo.descricao == 'Normal') {
                        quantities.quantityWithoutPromotion = estimatives.quantidade;
                        return;
                    }

                    quantities.quantityWithPromotion = estimatives.quantidade;
                });

                vaccine.numberOfDosesWithPromotion.quantity = quantities.quantityWithPromotion;
                vaccine.numberOfDosesWithoutPromotion.quantity = quantities.quantityWithoutPromotion;
            });
        },  

        sumOfDoses(withPromotion = false) {
            const key = withPromotion ? 'numberOfDosesWithPromotion' : 'numberOfDosesWithoutPromotion';
            const stateVar = withPromotion ? 'totalNumberOfDosesWithPromotion' : 'totalNumberOfDosesWithoutPromotion';

            if(this.formVacinasDoses.length == 1) {
                const doses = this.formVacinasDoses[0][key].quantity;
                this[stateVar] = !doses ? 0 : parseInt(doses);
                return;
            }

            const allDoses = this.formVacinasDoses.reduce((accumulator, currentValue) => {

                const accumulatorValue = typeof accumulator == 'number' ? accumulator : accumulator[key].quantity;

                return this.addAllDoses(accumulatorValue, currentValue[key].quantity); 
            }, 0);

            this[stateVar] = allDoses;
        },

        addAllDoses(valueFirst, valueSecond) {
            const valueFirstParsed = valueFirst ? parseInt(valueFirst): 0;
            const valueSecondParsed = valueSecond ? parseInt(valueSecond): 0;

            return valueFirstParsed + valueSecondParsed;
        },

        checkPromotion() {
            const hasPromotion = this.selectedIncentives.filter(incentive => incentive.nome !== 'Normal');

            if(hasPromotion.length !== 0) {
                return true;
            }
        },

        emitEventNumberDoses() {
            busEvent.$emit('changeNumberOfDoses', this.formVacinasDoses)
        },

        removeVacina(index){
            busEvent.$emit('vaccineExclusion', index)
        },

        emitEventDisableButtonSaveNewVaccine() {
            busEvent.$emit('disableButtonSaveNewVaccine', false);
        }
    }

}
</script>

<style scoped>
.min-with-vaciine-name {
    max-width: 140px;
    min-width: 140px;
}

.min-width-trash-button {
    max-width: 50px;
    min-width: 50px;
}

.font-weight-custom {
    font-weight: 800 !important;
}

.separator {
    border-left: 1px solid #EBE9F1;
    margin-left: 2rem;
}
</style>
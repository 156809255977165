<template>
    <section class="minimal-information">
        <CustomAlert variant="danger" message="A quantidade de doses precisa ser menor ou igual ao definido previamente. Altere os campos ou ajuste a quantidade." :show="showAlert"/>
        <CustomAlert variant="danger" message="A quantidade de doses precisa ser igual ao definido previamente. Altere os campos ou ajuste a quantidade." :show="showAlertEqual"/>
        <b-tabs content-class="mt-0" nav-wrapper-class="no-margin">
            <b-tab 
                v-for="(vaccine, index) in selectedVaccinesTabs.vaccines"
                :key="vaccine.id_vacina"
                title-link-class="font-tab" 
                title-item-class="custom-tab-style"
            >   
                <template #title>
                    <div class="d-flex justify-content-center align-items-center">
                        <feather-icon
                            v-show="vaccineWithError.includes(vaccine.id_vacina)"
                            id="erro-dose-distribution"
                            icon="AlertTriangleIcon"
                            class="text-danger"
                            size="18"
                        />
                        <h4 class="m-0">{{ vaccine.nome_tecnico }}</h4>
                    </div>
                </template>
                <main class="bg-white">
                    <WrapperFromVaccinePricingAndDosesDistributionCampaignEdit 
                        :idVaccine="vaccine.id_vacina"
                        :numberOfDoses="selectedVaccinesTabs.numberOfDoses ? selectedVaccinesTabs.numberOfDoses[index] : null"  
                        :incentives="selectedIncentives"
                        :vaccines="infosVaccineCampaign"
                        :editedVaccine="editedVaccine"
                        :situationCampaign="situationCampaignSelected"
                    />
                </main>
            </b-tab>
        </b-tabs>
    </section>
</template>

<script>
import { BCard, BTabs, BTab, BCardText } from 'bootstrap-vue'
import CustomAlert from '@/views/components/custom/timed-alert/CustomAlert.vue'
import WrapperFromVaccinePricingAndDosesDistributionCampaignEdit from './WrapperFromVaccinePricingAndDosesDistributionCampaignEdit.vue';

import { busEvent } from '@/main';

export default {
    components: {
        BCard, BTabs, BTab, BCardText, CustomAlert,
        WrapperFromVaccinePricingAndDosesDistributionCampaignEdit
    },

    data() {
        return {
            showAlert: false,
            timeoutControl: null,
            showAlertEqual: false,
            situationCampaignSelected: '',
            selectedVaccinesTabs: {
                vaccines: [],
                numberOfDoses: []
            },
            selectedIncentives: [],
            infosVaccineCampaign: [],

            saveVaccinePricing: [],
            saveDistributionRegion: [],
            saveDistributionUnidade: [],

            lastErrorRegion: null,
            lastErrorUnidade: null,

            errorRegion: [],
            errorUnidade: [],
            hasErrorRegion: [],

            vaccineWithError: [],
        }
    },

    mounted() {
        busEvent.$on('vaccinesEdited', (vaccines) => {
            this.editedVaccine = vaccines;
            this.selectedVaccinesTabs.vaccines = vaccines;
        });
        busEvent.$on('changeNumberOfDoses', (vaccineDoses) => {
            this.selectedVaccinesTabs.numberOfDoses = vaccineDoses;
        });
        busEvent.$on('dataCampanha', (campanha) => {
            this.selectedIncentives = campanha.incentives;
            this.infosVaccineCampaign = campanha.campanha.campanha_vacina;
        });
        busEvent.$on('situationCampaign', (situation) => {
            this.situationCampaignSelected = situation;
        });
        busEvent.$on('cleanErrors', () => {            
            this.showAlertEqual = false;
            this.showAlert = false;
        });

        busEvent.$on('errorDoseDistribution', (error) => {
            if(error.type == 'region') {
                this.separateErrors(this.errorRegion, error.vaccineId, error);
            } else if(error.type == 'unidade') { 
                this.separateErrors(this.errorUnidade, error.vaccineId, error);
            }
        });

        this.eventsPrepareDistribution();
    },

    methods: {
        separateErrors(errorData, vaccineId, error) {
            const vaccineError = errorData.filter(vaccine => vaccine.vaccineId == vaccineId)[0];

            if(!vaccineError) {
                errorData.push(error);
            } else {
                errorData.map(vaccine => {
                    if(vaccine.vaccineId == vaccineId) {
                        vaccine.error = error.error;
                    }
                });
            }

            if(this.errorRegion.length > 0) { this.checkErrorRegiao(this.errorRegion, vaccineId) }
            if(this.errorUnidade.length > 0) { this.checkErrorUnidade(this.errorUnidade, vaccineId) }

            this.errorRegion = [];
            this.errorUnidade = [];
        },
        
        checkErrorUnidade (errorData, vaccineId) {
            if(this.hasErrorRegion.includes(vaccineId)) { return; }

            let vaccineError = false;

            const hasError = errorData.some(errorVaccine => errorVaccine.error);

            if(hasError) { vaccineError = true;}

            this.fillError(vaccineError, vaccineId);
        },

        checkErrorRegiao(errorData, vaccineId) {
            let vaccineError = false;

            const hasError = errorData.some(errorVaccine => errorVaccine.error);

            if(hasError) {
                vaccineError = true; 
                this.hasErrorRegion.push(vaccineId);
            } else {
                this.hasErrorRegion = this.hasErrorRegion.filter(vaccine => vaccine != vaccineId);
            }

            this.fillError(vaccineError, vaccineId);
        },

        fillError(error, vaccineId) {
            if(error) {
                this.vaccineWithError.push(vaccineId);
            } else {
                this.vaccineWithError = this.vaccineWithError.filter(vaccine => vaccine != vaccineId);
            }

            this.removedVaccine();

            if(this.vaccineWithError.length > 0) { error = true; } 

            if(this.situationCampaignSelected != 'Rascunho') {
                this.showAlertEqual = error;
            } else {
                this.showAlert = error;
            }

            busEvent.$emit('disableButtonSave', { error });
        },

        removedVaccine() {
            const idVaccinesSelected = this.selectedVaccinesTabs.vaccines.map(vaccine => vaccine.id_vacina);
            const removedDuplicatedErrors = this.vaccineWithError.filter((vaccine, index) => this.vaccineWithError.indexOf(vaccine) === index); 

            let differenceVaccines = removedDuplicatedErrors.filter(vaccineSelected => !idVaccinesSelected.includes(vaccineSelected));

            differenceVaccines.map(vaccineId => {
                this.vaccineWithError = this.vaccineWithError.filter(vaccine => vaccine != vaccineId);
            })
        },

        handleSaveVaccinePricing(vaccinePricing) {
            this.saveVaccinePricing.push(vaccinePricing);

            if(this.selectedVaccinesTabs.vaccines.length == this.saveVaccinePricing.length) {
                busEvent.$emit('vaccinePricingDataCampaign', this.saveVaccinePricing);
                this.saveVaccinePricing = [];
            }
        },  

        handleSaveDistributionRegion(distributedRegion) {
            this.saveDistributionRegion.push(distributedRegion);

            if(this.selectedVaccinesTabs.vaccines.length == this.saveDistributionRegion.length) {
                busEvent.$emit('doseDistributionRegion', this.saveDistributionRegion);
                this.saveDistributionRegion = [];
            }
        },

        handleSaveDistributionUnidade(distributionUnidade) {
            this.saveDistributionUnidade.push(distributionUnidade);

            if(this.selectedVaccinesTabs.vaccines.length == this.saveDistributionUnidade.length) {
                busEvent.$emit('doseDistributionUnidade', this.saveDistributionUnidade);
                this.saveDistributionUnidade = [];
            }
        },

        eventsPrepareDistribution() {
            busEvent.$on('distributionRegionPrepare', (distributedRegion) => {
                this.handleSaveDistributionRegion(distributedRegion);
            });
            busEvent.$on('doseDistributionUnidadePrepare', (distributedUnidade) => {
                this.handleSaveDistributionUnidade(distributedUnidade);
            });
            busEvent.$on('vaccinePricingDataCampaignPrepare', (vaccinePricing) => {
                this.handleSaveVaccinePricing(vaccinePricing);
            });
        }
    }
}
</script>

<style>
.no-margin > ul {
    margin: 0;
}

.font-tab {
    font-size: 1.3rem;
}

.custom-tab-style:not(:last-child):after {
    content: "";
    position: absolute;
    right: 0;
    top: 12px;
    width: 1px;
    height: 18px;
    background-color: #B9B9C3;
}

.custom-tab-style > a::after {
    content: none !important;
}

.custom-tab-style > a {
    color: #5E5873 !important;
    background: #EFEFEF !important;
}

.custom-tab-style > a.active {
    background: #fff !important;
}
</style>
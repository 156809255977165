export default {
    fillUnidadesByRegion(vueInstance, regionUnidade, dadosUnidade, distribution) {
        const unidades = [];

        unidades.push({
            idUnidade: null,
            descricao: 'Reserva',
            noPromotion: regionUnidade.reservationNoPromotion,
            withPromotion: regionUnidade.reservationWithPromotion,
            total: null,
            tooltip: true,
        });

        if(dadosUnidade) {
            dadosUnidade.map(unidade => {
                unidades.push({
                    idUnidade: unidade.id_unidade,
                    descricao: unidade.descricao_unidade,
                    noPromotion: null,
                    withPromotion: null,
                    total: null,
                    tooltip: false,
                    editNoPromotion: false,
                    editWithPromotion: false,
                });
            })
        }

        if(distribution){
            distribution.map(distributedUnit => {

                let u = unidades.filter((unidade) => unidade.idUnidade == distributedUnit.id_unidade);

                if(u.length > 0){
                    if(distributedUnit.id_incentivo == 1){
                        u[0].noPromotion = distributedUnit.quantidade;
                        u[0].editNoPromotion = true;
                    }
                    if(distributedUnit.id_incentivo == 2){
                        u[0].withPromotion = distributedUnit.quantidade;
                        u[0].editWithPromotion = true;
                    }
                    
                }
                
            })
        }

        vueInstance.$set(regionUnidade, 'open', false);
        vueInstance.$set(regionUnidade, 'unidades', unidades);
        vueInstance.$set(regionUnidade, 'fields', [
            {key: 'actions', label: '', class: 'custom-width-actions-table px-1'},
            {key: 'descricao', label: 'Unidade', class: 'pl-1'},
            {key: 'noPromotion', label: 'Sem fomento'},
            {key: 'withPromotion', label: 'Com fomento'},
            {key: 'totalDoses', label: 'Total', class: 'text-center'},
        ]);
            
        this.updateSummary(vueInstance, regionUnidade);

        return unidades;
    },

    updateSummary(vueInstance, regionUnidade) {
        vueInstance.$set(regionUnidade, 'dosesSummary', {
            totalDosesWithPromotion: regionUnidade.withPromotion ? parseInt(regionUnidade.withPromotion) : 0,
            totalDosesWithoutPromotion: regionUnidade.noPromotion ? parseInt(regionUnidade.noPromotion) : 0,

            reservationWithPromotion: 0,
            reservationWithoutPromotion: 0,

            totalDistributedDosesWithPromotion: 0,
            totalDistributedDosesWithoutPromotion: 0,

            controlDosesWithPromotion: regionUnidade.withPromotion ? parseInt(regionUnidade.withPromotion) : 0,
            controlDosesWithoutPromotion: regionUnidade.noPromotion ? parseInt(regionUnidade.noPromotion) : 0,
        });
    }
}